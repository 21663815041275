<template>
  <div id="masterplan">
    <div class="container">
      <div class="row vh-100">
        <div class="col-xl-4 col-12 align-self-center">
            <div class="slideCaption text-focus-in delay-05">
              <h1 v-html="masterplan.title"></h1>

              <p v-html="masterplan.description"></p>

              <template v-for="cta in masterplan.gallery" :key="cta.id">
                <button class="cta" data-toggle="modal" :data-target="'#modal'+cta.id" @click="updateVideo()">
                  <span class="icn"><img width="40" height="40" src="@/assets/images/icons/Play.svg" alt="How to get there"/></span>
                  <span class="txt"> {{ cta.call_to_action }}</span>
                </button>

                <teleport to="body">
                  <modal :data="cta"></modal>
                </teleport>
              </template>
          </div>
        </div>
        <div class="col-xl-8 col-12 puff-in-center align-self-center">
            <div class="imageContainer">
              <img
                :src="getImage(masterplan.master_image,'general')"
                id="myimage"
                alt="Mountain View MasterPlan"
                class="slideImage"
              />

              <highlights />
            </div>

        </div>
      </div>
    </div>
  </div>
  <circles />
</template>

<script>
import circles from '@/components/circles.vue';
import highlights from '@/components/masterplan/highlights.vue';
import {mapState, mapGetters} from 'vuex';

export default {
  name: "Masterplan",
  components:{ 
    circles,
    highlights,
  },
  beforeCreate() {
    this.$store.dispatch('loadProject',{domain:'hyde'});
    this.$store.dispatch('loadNotification',{domain:'hyde'});
    this.$store.dispatch('loadPlans',{domain:'hyde'});
    this.$store.dispatch('loadMasterplan',{domain:'hyde'});
  },
  computed:{
    ...mapGetters(['getImage','getPlan']),
    ...mapState(['masterplan'])
  },
  renderTriggered() {
    this.updateSVG();
  },
  methods: {
    updateVideo(){
      var vidDefer = document.getElementsByTagName('iframe');
        for (var i=0; i<vidDefer.length; i++) {
          if(vidDefer[i].getAttribute('data-src')) {
          vidDefer[i].setAttribute('src',vidDefer[i].getAttribute('data-src'));
          }
        }
    },
    updateSVG(){
      const img = new Image();
      img.onload = function() {
        // console.log(this.width + 'x' + this.height);
        let viewBox = '0 0 '+this.width+' '+this.height;

        document.getElementById('master_svg').setAttribute('viewBox',viewBox);
      }
      img.src = this.getImage(this.masterplan.master_image,'general');
    }
  }
};

</script>

<style>
  #masterStatus{background-color:white;padding:1rem;border-radius: 0.7rem;}
  #masterStatus strong{display:block;}
  #masterStatus h3{font-size:1.3rem; margin-bottom: 1rem;}
  #masterStatus p{margin:0;}
</style>
<style scoped>


#masterplan {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#masterplan .slideCaption {
  max-width: 30rem;
}
#masterplan .slideCaption h3{
  font-style:italic ;
  font-size:1rem;
  margin-bottom:20px;
}
#masterplan .slideCaption p {
  font-size:1.1rem;
  font-weight:600;
}
.imageContainer {
  display: flex;
  justify-content: center;
  position: relative;
}
.slideImage {
    max-width:55vw;
    width: 100%;
    display: block;
    height: 100%;
}
</style>
